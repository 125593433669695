// landing

.upload_wrapper {
  // only used to fade in upload when css is loaded
  opacity: 1 !important;
}
.upload_dropzone {
  top: 0;
  right: 0;
  bottom: 142px;
  left: 0;
  .dropzone {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    padding: 0;
    border: 0;
    background: white;
    * {
      pointer-events: none;
    }
  }
  .upload_dragAndDrop-available {
    margin: 24px;

    border: 2px dashed $color-unnamed1;
    border-radius: 4px;
    transition-duration: 0.25s;
    transition-property: border;
    &.dz-drag-hover {
      border: 2px dashed $color-white;
    }
  }

  &.upload_dropzone-edit {
    position: fixed;
    top: 34px;
    right: inherit;
    bottom: inherit;
    left: $sideNavWidth + 80px;
    z-index: 8;
    transition: left 0.25s ease-in;

    &.upload_dropzone-edit_push_left {
      left: $openSideNavWidth + 80px;
    }

    .dropzone {
      position: relative;
      top: 42px;
      right: inherit;
      bottom: inherit;
      left: 85px;
      margin: 0;

      padding: 0;
      border: 0;
    }
  }
}

.upload_dropzone_content {
  position: absolute;
  top: calc(50% - 100px);
  right: 0;
  bottom: 0;
  left: 0;

  opacity: 1;
  transition: opacity 0.2s ease-in;

  &.upload_dropzone_content-covered {
    opacity: 0.2;
  }

  .upload_dropzone_content-centered {
    text-align: center;
  }
  .upload_dropzone_h2 {
    margin-bottom: 40px;

    color: $color-black5;
  }
  .upload_dropzone_subline {
    @include font-size(14);

    color: $color-grey3;
  }

  .upload_dropzone_content_drophint {
    @include font-size(14);

    padding-top: 32px;
    color: $color-grey3;
    .eyecon {
      @include font-size(24);

      vertical-align: middle;
    }
  }
}

.upload_dropzone_dragcover {
  position: absolute;
  top: calc(50% - 170px);
  right: 0;
  bottom: 0;
  left: 0;

  opacity: 0;
  transition: opacity 0.2s ease-in;

  .upload_dropzone_dragcover_circle {
    position: relative;
    margin: 0 auto;

    width: 360px;
    height: 360px;
    border-radius: 180px;
    background: $color-aqua;

    .dragcover_image {
      position: absolute;
      background-color: $color-aqua;
    }

    .dragcover_image-backLeft,
    .dragcover_image-backRight {
      opacity: 0.4;
    }

    .dragcover_image-centered,
    .dragcover_image-backRight,
    .dragcover_image-backLeft {
      top: calc(50% - 40px);
      left: calc(50% - 40px);

      transition: all 0.2s ease-in-out;
    }
  }

  &.upload_dropzone_dragcover-shown {
    opacity: 1;

    .dragcover_image-backLeft {
      top: calc(40% - 40px);
      left: calc(30% - 25px);

      transform: translate3d(0, 0, 0) rotate(-20deg);
    }
    .dragcover_image-backRight {
      top: calc(40% - 40px);
      left: calc(60% - 25px);

      transform: translate3d(0, 0, 0) rotate(20deg);
    }
  }

  .upload_dropzone_dragcover-label {
    @include font-size(16);

    position: absolute;
    top: 65%;
    right: 0;
    left: 0;

    color: $color-white;
    text-align: center;
  }
}

// edit
.upload_edit_topbar_content,
.upload_postButton_wrapper {
  padding: 12px 32px;
}

.upload_edit_topbar_selectAll,
.upload_edit_topbar_picker_trigger {
  @include font-regular();
  @include font-size(14);

  display: inline-block;
  margin: 8px 40px 0 0;

  color: $color-grey3;
}

.upload_edit_topbar_selectAll {
  position: relative;
}

.upload_edit_topbar_selectAll_hint {
  @include font-regular();
  @include font-size(14);

  display: none;
  float: right;
  margin-right: 40px;

  color: $color-grey3;
  @media #{$large-up} {
    display: inline;
  }
}

.upload_postButton_wrapper {
  height: 64px;
  border-bottom: 1px solid $color-grey5;
  background-color: $color-grey7;
}

.upload_edit_sidebar_form {
  // this is needed for the positioning of the addToMarket Checkbox
  position: relative;
  margin: 12px 32px;
}

.upload_edit_sidebar_text {
  @include font-size(14);

  color: $color-grey3;
}

.upload_edit_sidebar_discoverBar_level0 {
  color: $color-unnamed24;
}

.upload_edit_sidebar_discoverBar_level1,
.upload_edit_sidebar_discoverBar_level2 {
  color: $color-orange;
}

.upload_edit_sidebar_discoverBar_level3 {
  color: $color-aqua;
}

.upload_edit_sidebar_discoverBar {
  height: 8px;
  border-radius: 4px;
  background-position: 0 center;
  background-size: 200%;
  transition: background 0.5s ease-in-out;

  &.upload_edit_sidebar_discoverBar_level0 {
    background-image: linear-gradient(
      to right,
      $color-grey5 0%,
      $color-grey5 50%,
      $color-unnamed24 50%,
      $color-unnamed24 100%
    );
  }

  &.upload_edit_sidebar_discoverBar_level1,
  &.upload_edit_sidebar_discoverBar_level2 {
    background-image: linear-gradient(
      to right,
      $color-grey5 0%,
      $color-grey5 50%,
      $color-orange 50%,
      $color-orange 100%
    );
  }

  &.upload_edit_sidebar_discoverBar_level3 {
    background-image: linear-gradient(
      to right,
      $color-grey5 0%,
      $color-aqua 50%,
      $color-aqua 50%,
      $color-aqua 100%
    );
  }
}

.upload_edit_sidebar_subHeadline {
  @include font-size(14);

  margin-top: 24px;
  margin-bottom: 8px;
}

.upload_edit_sidebar_albumCount {
  @include font-size(14);

  float: right;
  margin-top: 10px;

  color: $color-grey4;
}

.upload_edit_sidebar_addToMarket {
  position: relative;
  margin-top: 24px;
  margin-bottom: 100px;
}
